import querystring from 'querystring';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import {
    getPage,
    getRedirect,
    getAllPages,
    WagtailApiResponseError,
} from '../api/wagtail';
import LazyContainers from '../containers/LazyContainers';

const isProd = process.env.NODE_ENV === 'production';
const fallbackTheme = process?.env?.SITE_NAME || 'Medborgarskolan';

export default function CatchAllPage({ componentName, componentProps }) {
    if (!componentName) {
        return <h1>Component name is undefined</h1>;
    }

    const Component = LazyContainers[componentName];
    if (!Component) {
        return <h1>Component {componentName} not found</h1>;
    }
    return <Component {...componentProps} containerName={componentName} />;
}

// For SSR
export async function getServerSideProps({ req, params, res, locale = 'sv' }) {
    let path = params?.path || [];
    path = path.join('/');

    const { host } = req.headers;
    let queryParams = new URL(req.url, `https://${host}`).search;
    if (queryParams.indexOf('?') === 0) {
        queryParams = queryParams.substr(1);
    }
    queryParams = querystring.parse(queryParams);
    queryParams.host = host;

    // Try to serve page
    try {
        const {
            json: { componentName, componentProps, redirect, customResponse },
            headers,
        } = await getPage(path, queryParams, {
            headers: {
                cookie: req.headers.cookie,
                host,
            },
        });

        // Forward any cookie we encounter
        const cookies = headers.get('set-cookie');
        if (cookies) {
            res.setHeader('Set-Cookie', cookies);
        }
        const acceptedLang = headers.get('accept-language');
        if (acceptedLang) {
            res.setHeader('Accept-Language', acceptedLang);
        }
        const namespace = headers.get('x-translation-namespace');
        if (namespace) {
            res.setHeader('X-Translation-Namespace', namespace);
        }

        // Set language depending on page language and theme
        const currNamespace = namespace || fallbackTheme.toLowerCase();
        const i18n = await serverSideTranslations(acceptedLang || locale, [
            ...(currNamespace ? [currNamespace] : []),
            'default',
        ]);

        if (customResponse) {
            const { body, body64, contentType } = customResponse;
            res.setHeader('Content-Type', contentType);
            res.statusCode = 200;
            res.write(body64 ? Buffer.from(body64, 'base64') : body);
            res.end();

            return { props: { ...i18n } };
        }

        if (redirect) {
            const { destination, isPermanent } = redirect;
            return {
                redirect: {
                    destination: destination,
                    permanent: isPermanent,
                },
            };
        }

        return { props: { componentName, componentProps, ...i18n } };
    } catch (err) {
        if (!(err instanceof WagtailApiResponseError)) {
            throw err;
        }

        // When language or locale doesn't exist
        const namespace = fallbackTheme.toLowerCase();
        const i18n = await serverSideTranslations(locale, [
            ...(namespace ? [namespace] : []),
            'default',
        ]);

        // When in development, show django error page on error
        if (!isProd && err.response.status >= 500) {
            const html = await err.response.text();
            return {
                props: {
                    componentName: 'PureHtmlPage',
                    componentProps: { html },
                    ...i18n,
                },
            };
        }

        if (err.response.status >= 500) {
            throw err;
        }
    }

    // Try to serve redirect
    try {
        const { json: redirect } = await getRedirect(path, queryParams, {
            headers: {
                cookie: req.headers.cookie,
                host,
            },
        });
        const { destination, isPermanent } = redirect;
        return {
            redirect: {
                destination: destination,
                permanent: isPermanent,
            },
        };
    } catch (err) {
        if (!(err instanceof WagtailApiResponseError)) {
            throw err;
        }

        if (err.response.status >= 500) {
            throw err;
        }
    }

    // Serve 404 page
    return { notFound: true };
}

// For SSG
/*
export async function getStaticProps({ params, preview, previewData }) {
    params = params || {};
    let path = params.path || [];
    path = path.join("/");

    const { json: pageData } = await getPage(path);
    return { props: pageData }
}

export async function getStaticPaths() {
    const { json: data } = await getAllPages();

    let htmlUrls = data.items.map(x => x.relativeUrl);
    htmlUrls = htmlUrls.filter(x => x);
    htmlUrls = htmlUrls.map(x => x.split("/"));
    htmlUrls = htmlUrls.map(x => x.filter(y => y))
    htmlUrls = htmlUrls.filter(x => x.length)

    const paths = htmlUrls.map(x => (
        { params: { path: x } }
    ));

    return {
        paths: paths,
        fallback: false,
    };
}
*/
